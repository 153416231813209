<template>
  <div>
    <div class="mb-5">
      <v-sheet class="pa-3 rounded-xl" v-if="loading">
        <v-row>
          <v-col :cols="3">
            <v-skeleton-loader
              class="rounded-xl"
              type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col md="4" class="d-none d-sm-block">
            <v-skeleton-loader
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet>
      <v-card v-if="artist" class="mx-auto rounded-xl" width="100%">
        <v-img gradient="to right, rgba(0,0,0,.9), rgba(0,0,0,.7)" class="white--text align-end rounded-xl" height="230" :src="artist.ytChannel.thumbnails[0].medium.url">
          <div class="song-details d-flex">
            <div class="rounded-xl img-container">
              <v-img max-width="190" height="190" class="rounded-xl img d-none d-sm-block" :src="artist.ytChannel.thumbnails[0].medium.url"></v-img>
            </div>
            <div class="ml-4 details d-flex flex-column justify-center">
              <h1>{{ artist.ytChannel.title }}</h1>
              <h3>{{ artist.ytChannel.description }}</h3>
<!--              <h2>{{ track.spotify.album }}</h2>-->
<!--              <h3 class="mb-5">By {{ track.spotify.artists.join(', ') }}</h3>-->
<!--              <add-cover-modal @fetchCovers="fetchCovers"/>-->
            </div>
          </div>
        </v-img>
      </v-card>
    </div>
    <router-view :key="$route.params.slug" />
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: this.title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Find all the Latest artists on CoverSingers.com'
      }]
    }
  },
  data () {
    return {
      error: false,
      artist: null,
      loading: true
    }
  },
  computed: {
    title () {
      return `${this.artist && this.artist.ytChannel.title} cover songs | CoverSingers`
    }
  },
  created () {
    this.fetchArtist()
  },
  methods: {
    async fetchArtist () {
      try {
        if (this.$route.params.slug) {
          const res = await this.axios.get(`/api/v1/artists/p/${this.$route.params.slug}`)
          this.artist = res.data.data.artist
        }
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    '$route.params.slug' () {
      this.fetchArtist()
    }
  }
}
</script>
<style lang="scss" scoped>
  .song-details {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    .details {
      font-size: 0.75rem !important;
    }
    .img-container {
      .img {
        z-index: -1;
      }
    }
  }
</style>
