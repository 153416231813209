<template>
  <div class="artists-list">
    <!--    {{ spotifySongs && spotifySongs[4].artists.map(artist => artist.name) }}-->
    <artists-listing
      :page="page"
      :artists="artists"
      :limit="limit"
      :isAdmin="false"
      :loading="loading" />
    <v-pagination
      class="mt-5"
      v-model="page"
      v-if="paginationLength > 1"
      :length="paginationLength"
    ></v-pagination>
  </div>
</template>

<script>
import ArtistsListing from '../../components/ArtistsComponents/List'
export default {
  metaInfo () {
    return {
      title: 'Artists Listing',
      titleTemplate: '%s | CoverSingers',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: ' Find all the Latest artists on CoverSingers.com'
      }]
    }
  },
  props: {
    view: {
      type: String,
      default: 'dashboard'
    }
  },
  data () {
    return {
      loading: true,
      artists: [],
      limit: 18,
      total: 0,
      page: parseInt(this.$route.params.page) || 1
    }
  },
  async mounted () {
    await this.fetchArtists(this.page)
  },
  computed: {
    paginationLength () {
      return Math.ceil(this.total / this.limit)
    }
  },
  methods: {
    async fetchArtists (page) {
      try {
        this.page = page || 1
        this.loading = true
        const artistsData = await this.axios.get('/api/v1/artists/', {
          params: {
            page: page || this.page,
            limit: this.limit
          }
        })
        this.artists = artistsData.data.data.artists
        this.total = artistsData.data.total
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    page (newValue) {
      this.$router.push({
        name: 'ArtistsList',
        params: {
          page: newValue
        }
      })
    },
    '$route.params.page' (newValue) {
      this.fetchArtists(parseInt(newValue))
    }
  },
  components: {
    ArtistsListing
  }
}
</script>
