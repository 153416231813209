<template>
  <div class="admin-tracks-list">
    <div class="mb-5">
      <v-sheet class="pa-3 rounded-xl" v-if="loading">
        <v-row>
          <v-col :cols="3">
            <v-skeleton-loader
              class="rounded-xl"
              type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col md="4" class="d-none d-sm-block">
            <v-skeleton-loader
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-sheet>
      <v-card class="mx-auto rounded-xl" width="100%" v-if="track">
        <v-img gradient="to right, rgba(0,0,0,.9), rgba(0,0,0,.7)" class="white--text align-end rounded-xl" height="230" :src="track.spotify.images[0].url">
          <div class="song-details d-flex">
            <div class="rounded-xl img-container">
              <v-img max-width="190" height="190" class="rounded-xl img d-none d-sm-block" :src="track.spotify.images[0].url"></v-img>
            </div>
            <div class="ml-4 details d-flex flex-column justify-center">
              <h1>{{ track.spotify.name }} Cover Songs</h1>
              <h2>{{ track.spotify.album }}</h2>
              <h3 class="mb-5">By {{ track.spotify.artists.join(', ') }}</h3>
            </div>
          </div>
        </v-img>
      </v-card>
    </div>
    <router-view :key="$route.params.slug"/>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: `${this.track && this.track.spotify.name} Cover Songs`,
      titleTemplate: '%s | CoverSingers',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.track && `All cover songs of the track - ${this.track.spotify.name}, ${this.track.spotify.album} sung by ${this.track.spotify.artists.join(', ')}`
      }]
    }
  },
  data () {
    return {
      error: false,
      track: null,
      loading: true
    }
  },
  async mounted () {
    await this.fetchOST()
  },
  methods: {
    async fetchOST () {
      try {
        this.loading = true
        if (this.$route.params.slug) {
          const track = await this.axios.get(`/api/v1/tracks/p/${this.$route.params.slug}`)
          this.track = track.data.data.song
        }
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    '$route.params.slug' () {
      this.fetchOST()
    }
  }
}
</script>
<style lang="scss" scoped>
  .song-details {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    .details {
      font-size: 0.75rem !important;
    }
    .img-container {
      .img {
        z-index: -1;
      }
    }
  }
</style>
