<template>
  <div>
    <div class="videoWrapper" v-if="songLoading">
      <v-skeleton-loader class="skeleton-c" type="image, image, image, image"></v-skeleton-loader>
    </div>
    <div v-else-if="song">
      <div class="videoWrapper">
        <!-- Copy & Pasted from YouTube -->
        <iframe  width="100%" :src="`https://www.youtube.com/embed/${song.yt.videoId}/?autoplay=1&rel=0&modestbranding=1`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <v-card class="mx-auto rounded-b-xl rounded-t-0" flat>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              <router-link :to="{name: 'ArtistCovers', params: {slug: song.artists[0].slug }}">{{ song.artists[0].ytChannel.title}}</router-link>
            </div>
            <v-list-item-title class="headline mb-1">
              {{ song.ost.spotify.name }} | {{ song.ost.spotify.album }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ trackDate }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar tile class="rounded-circle" size="80">
            <router-link :to="{name: 'ArtistCovers', params: {slug: song.artists[0].slug }}">
              <v-img class="rounded-circle" :src="song.artists[0].ytChannel.thumbnails[0].default.url"></v-img>
            </router-link>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </div>
    <div v-if="songError">
      {{ songError }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    song: {
      type: Object,
      default: null
    },
    songLoading: {
      type: Boolean,
      default: true
    },
    songError: {
      type: String,
      default: ''
    }
  },
  computed: {
    trackDate () {
      const date = new Date(this.song.yt.publishedAt)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      return `${da} ${mo} ${ye}`
    }
  }
}
</script>
