<template>
  <div>
    <v-row v-if="loading">
      <v-col :lg="3" :md="4" :cols="6" v-for="i in 8" :key="i.id" class="mb-5">
        <v-skeleton-loader
          class="rounded-xl"
          type="image"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="rounded-xl mt-3"
          type="table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col :lg="3" :md="4" :cols="6" v-for="cover in covers" :key="cover.id">
        <cover-track-public :track="cover" v-if="cover"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CoverTrackPublic from '../../CoversComponents/CoverTrackPublic'

export default {
  props: {
    covers: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CoverTrackPublic
  }
}
</script>

<style scoped>

</style>
