<template>
  <div class="admin-tracks-list">
    <covers-listing
      :page="page"
      :tracks="songs"
      :limit="limit"
      :isAdmin="false"
      :loading="coversLoading" />
    <v-pagination
      class="mt-5"
      v-model="page"
      v-if="paginationLength > 1"
      :length="paginationLength"
    ></v-pagination>

  </div>
</template>

<script>
import CoversListing from '../../components/CoversComponents/List'
export default {
  data () {
    return {
      songs: [],
      error: false,
      track: null,
      limit: 12,
      ostLoading: true,
      coversLoading: true,
      total: 0,
      page: parseInt(this.$route.params.page) || 1
    }
  },
  computed: {
    paginationLength () {
      return Math.ceil(this.total / this.limit)
    }
  },
  async mounted () {
    if (this.$route.params.slug) {
      await this.fetchCovers(this.page)
    }
  },
  methods: {
    async fetchCovers (page) {
      try {
        this.page = page || 1
        this.coversLoading = true
        const songData = await this.axios.get(`/api/v1/covers/p/c/${this.$route.params.slug}`, {
          params: {
            page: this.page,
            limit: this.limit
          }
        })
        this.$emit('paginationLength', Math.ceil(songData.data.total / this.limit))
        this.songs = songData.data.data.songs
        this.total = songData.data.total
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.coversLoading = false
      }
    }
  },
  components: {
    CoversListing
  },
  watch: {
    page (newValue) {
      this.$router.push({
        name: 'CoversList',
        params: {
          page: newValue
        }
      })
    },
    '$route.params.page' (newValue) {
      this.fetchCovers(parseInt(newValue))
    }
  }
}
</script>
<style lang="scss" scoped>
  .song-details {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    .details {
      font-size: 0.75rem !important;
    }
    .img-container {
      .img {
        z-index: -1;
      }
    }
  }
</style>
