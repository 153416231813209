<template>
  <div>
    <v-card class="mr-0 rounded-xl" flat max-width="100%" :to="{name: 'SingleCoverSong', params: {slug: track.slug}}" elevation="0">
      <v-img class="white--text align-end" :src="track.yt.thumbnails[0].medium.url"></v-img>
    </v-card>
    <v-card flat color="transparent" :to="{name: 'SingleCoverSong', params: {slug: track.slug}}" class="rounded-xl">
      <div class="avatar-container">
        <v-list-item-avatar tile class="rounded-circle mt-0 avatar" size="40">
          <v-img class="rounded-circle" :src="track.ost.spotify.images[2].url"></v-img>
        </v-list-item-avatar>
      </div>
      <v-card-title class="pl-13 pt-1 text-truncate">
        <h6 class="text-truncate">{{ `${track.ost.spotify.name} - ${track.ost.spotify.album}` }}</h6>
      </v-card-title>
      <v-card-subtitle class="pl-13">
        {{ trackDate(track.yt.publishedAt) }}
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    track: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    trackDate (d) {
      const date = new Date(d)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      return `${da} ${mo} ${ye}`
    }
  }
}
</script>
<style lang="scss" scoped>
  .avatar-container {
    position: relative;
  }
  .avatar {
    position: absolute;
    top: 0.75rem;
  }
  .dots {
    position: absolute;
    right: 0;
    top: 1rem;
  }
</style>
