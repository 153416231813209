<template>
  <div>
    <v-row v-if="loading">
      <v-col :lg="2" :cols="6" :md="3" :sm="4" :xl="2" v-for="i in limit" :key="i.id">
        <v-skeleton-loader
          elevation="0"
          class="rounded-xl"
          v-bind="attrs"
          type="image, list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col :lg="2" :cols="6" :md="3" :sm="4" :xl="2" v-for="artist in artists" :key="artist.id">
        <single-item :artist="artist" v-if="artist" :is-admin="isAdmin" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SingleItem from './SingleItem'

export default {
  props: {
    page: {
      type: Number,
      default: 1
    },
    artists: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 18
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attrs: {
        elevation: 1
      }
    }
  },
  components: {
    SingleItem
  }
}
</script>

<style scoped>

</style>
