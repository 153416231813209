<template>
  <div class="artists-list">
    <!--    {{ spotifySongs && spotifySongs[4].artists.map(artist => artist.name) }}-->
    <artist-covers-list
      :page="page"
      :covers="covers"
      :limit="limit"
      :isAdmin="false"
      :loading="loading" />
    <v-pagination
      class="mt-5"
      v-model="page"
      v-if="paginationLength > 1"
      :length="paginationLength"
    ></v-pagination>
  </div>
</template>

<script>
import ArtistCoversList from '../../components/ArtistsComponents/ArtistCovers/List'
export default {
  props: {
    view: {
      type: String,
      default: 'dashboard'
    }
  },
  data () {
    return {
      loading: true,
      covers: [],
      limit: 12,
      total: 0,
      coversLoading: true,
      page: parseInt(this.$route.params.page) || 1
    }
  },
  async mounted () {
    await this.fetchCovers(this.page)
  },
  computed: {
    paginationLength () {
      return Math.ceil(this.total / this.limit)
    }
  },
  methods: {
    async fetchCovers (page) {
      try {
        this.page = page || 1
        this.loading = true
        const songData = await this.axios.get(`/api/v1/artists/p/${this.$route.params.slug}/covers/`, {
          params: {
            page: page || this.page,
            limit: this.limit
          }
        })
        this.covers = songData.data.data.covers
        this.total = songData.data.totalArtistCovers
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    page (newValue) {
      this.$router.push({
        name: 'ArtistCovers',
        params: {
          page: newValue
        }
      })
    },
    '$route.params.page' (newValue) {
      this.fetchCovers(parseInt(newValue))
    }
  },
  components: {
    ArtistCoversList
  }
}
</script>
