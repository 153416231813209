<template>
  <div class="mt-10">
    <v-sheet v-if="byOthersLoading">
      <h3 class="px-4 pt-4" v-if="originalSong">
        {{ originalSong.ost.spotify.name }} by other Artists
      </h3>
      <v-slide-group show-arrows>
        <v-slide-item v-for="n in 5" :key="n.id">
          <div class="ma-5">
            <v-skeleton-loader
              class="mr-0 rounded-xl"
              width="250"
              v-if="!byOthersLoading"
              type="image, table-tfoot"
            ></v-skeleton-loader>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-sheet class="mx-auto rounded-xl" v-else-if="byOthers.length > 0">
      <h3 class="px-4 pt-4" v-if="originalSong">
        {{ originalSong.ost.spotify.name }} by other Artists
      </h3>
      <div class="by-others">
        <div v-for="cover in byOthers" :key="cover.id">
          <v-card class="my-4" rounded flat max-width="250" :to="{name: 'SingleCoverSong', params: {slug: cover.slug}}" elevation="0">
            <v-img class="white--text align-end" :src="cover.yt.thumbnails[0].medium.url">
            </v-img>
            <div class="avatar-container">
              <v-list-item-avatar tile class="rounded-circle mt-0 avatar" size="40">
                <v-img class="rounded-circle" :src="cover.artists[0].ytChannel.thumbnails[0].default.url"></v-img>
              </v-list-item-avatar>
            </div>
            <v-card-title class="pl-13 pt-1 text-truncate">
              <span class="text-truncate">{{ cover.artists[0].ytChannel.title }}</span>
            </v-card-title>
            <v-card-subtitle class="pl-13">
              {{ trackDate(cover.yt.publishedAt) }}
            </v-card-subtitle>
          </v-card>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    byOthers: {
      type: Array,
      default: () => []
    },
    byOthersLoading: {
      type: Boolean,
      default: true
    },
    byOthersError: {
      type: String,
      default: ''
    },
    originalSong: {
      type: Object,
      default: null
    }
  },
  methods: {
    trackDate (d) {
      const date = new Date(d)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      return `${da} ${mo} ${ye}`
    }
  }
}
</script>

<style scoped lang="scss">
  .avatar-container {
    position: relative;
  }
  .avatar {
    position: absolute;
    top: 0.75rem;
  }
  .by-others {
    display: flex;
    overflow-x: scroll;
    margin-left: 1rem;
    margin-right: 1rem;
    & > div {
      padding-right: 1rem;
    }
    .item {
      flex-shrink: 1;
    }
  }

</style>
