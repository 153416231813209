<template>
  <div class="admin-tracks-list">
    <!--    {{ spotifySongs && spotifySongs[4].artists.map(artist => artist.name) }}-->
    <track-listing
      :page="page"
      :songs="songs"
      :limit="limit"
      :isAdmin="false"
      :loading="loading" />
    <v-pagination
      class="mt-5"
      v-model="page"
      v-if="paginationLength > 1"
      :length="paginationLength"
    ></v-pagination>
  </div>
</template>

<script>
import TrackListing from '../../components/Admin/TrackListComponents/List'
export default {
  metaInfo () {
    return {
      title: 'Tracks Listing',
      titleTemplate: '%s | CoverSingers',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: ' Find all the covers of latest tracks on CoverSingers.com'
      }]
    }
  },
  props: {
    view: {
      type: String,
      default: 'dashboard'
    }
  },
  data () {
    return {
      loading: true,
      songs: [],
      limit: 18,
      total: 0,
      page: parseInt(this.$route.params.page) || 1
    }
  },
  async mounted () {
    await this.fetchSongs()
  },
  computed: {
    paginationLength () {
      return Math.ceil(this.total / this.limit)
    }
  },
  methods: {
    async fetchSongs (page) {
      try {
        this.page = page || 1
        this.loading = true
        const songData = await this.axios.get('/api/v1/tracks/', {
          params: {
            page: page || this.page
          }
        })
        this.songs = songData.data.data.songs
        this.total = songData.data.total
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    page (newValue) {
      this.$router.push({
        name: 'TracksList',
        params: {
          page: newValue
        }
      })
    },
    '$route.params.page' (newValue) {
      this.fetchSongs(parseInt(newValue))
    }
  },
  components: {
    TrackListing
  }
}
</script>
