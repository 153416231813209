<template>
  <div>
    <v-card class="mb-10 pa-5 rounded-xl" v-if="isAdmin && song" elevation="0">
      <h2>Admin Controls</h2>
      <v-btn class="mt-4 d-flex align-center" @click="featureCover" color="secondary" v-if="song.featured" :disabled="loading">
        Remove Feature <v-icon small color="white" class="ml-3">mdi-star</v-icon>
      </v-btn>
      <v-btn class="mt-4 d-flex align-center" @click="featureCover" color="secondary" v-else :disabled="loading">
        Feature this cover song <v-icon small color="yellow" class="ml-3">mdi-star</v-icon>
      </v-btn>
    </v-card>
    <cover-song-public :songLoading="songLoading" :song="song" :songError="songError" />
    <similar-covers :byOthers="byOthers" :byOthersLoading="byOthersLoading" :byOthersError="byOthersError" :originalSong="song" />
  </div>
</template>

<script>
import CoverSongPublic from '../../components/CoversComponents/CoverSongPublic'
import SimilarCovers from '../../components/CoversComponents/SimilarCovers'
import { fetchSingleCover, fetchCoversByOST } from '../../services'
export default {
  metaInfo () {
    const artists = this.song && this.song.artists.map(artist => artist.ytChannel.title)
    return {
      title: this.song && `${this.song.ost.spotify.name} | ${this.song.ost.spotify.album} by ${artists[0]}`,
      titleTemplate: '%s | CoverSingers',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.song && `Listen to the cover song of ${this.song.ost.spotify.name} | ${this.song.ost.spotify.album} by ${artists[0]} on coversingers.com`
      }]
    }
  },
  data () {
    return {
      songLoading: true,
      byOthersLoading: true,
      song: null,
      songError: null,
      byOthersError: null,
      byOthers: null,
      loading: false
    }
  },
  mounted () {
    this.getCoverSong()
  },
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  methods: {
    async getCoverSong () {
      const slug = this.$route.params.slug
      try {
        const songData = await fetchSingleCover(slug)
        this.song = songData.data.data.song
        this.getCoversByOST(this.song.ost._id, this.song._id)
      } catch (e) {
        this.songError = e.response.data.message
      } finally {
        this.songLoading = false
      }
    },
    async getCoversByOST (pid, id) {
      try {
        const covers = await fetchCoversByOST(pid, id)
        this.byOthers = covers.data.data.songs
      } catch (e) {
        this.byOthersError = e.response.data.message
      } finally {
        this.byOthersLoading = false
      }
    },
    async deleteCover () {
      try {
        await this.axios.delete(`/api/v1/covers/${this.song._id}`)
        this.$emit('fetchCovers')
      } catch (e) {
        this.$snackbar.showMessage({ content: 'Cover songs is NOT deleted!' })
      }
    },
    async featureCover () {
      try {
        this.loading = true
        await this.axios.patch(`/api/v1/covers/${this.song._id}`, {
          featured: !this.song.featured
        })
        this.getCoverSong()
      } catch (e) {
        this.$snackbar.showMessage({ content: 'Cover songs is not Featured!' })
      } finally {
        this.loading = false
      }
    }

  },
  watch: {
    '$route.params.slug' () {
      this.songLoading = true
      this.getCoverSong()
    }
  },
  components: {
    CoverSongPublic,
    SimilarCovers
  }
}
</script>
